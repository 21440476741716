<template>
  <div class="trailerListing">
    <!-- <keep-alive v-if="!purgeContent"> -->
    <div class="card-container" v-for="(content, index) in trailerContents" :key="index" @click="playTrailer(index)">
      <div class="margin">
        <categoryCard :content="content" :displayTags="false" :cardType="fetchCardType"> </categoryCard>
      </div>
      <p class="trailer-title">{{ content.title }}</p>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { mapGetters } from 'vuex';

export default {
  props: {
    content: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      trailerContents: [],
      playbackInitiated: false,
      purgeContent: false,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),

    fetchCardType() {
      if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "75px",
          width: "133px",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      } else {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "93px",
          width: "165px",
          quality: "THUMBNAIL",
          playButton: { enablePlay: true, height: "40px", width: "40px" },
        };
      }
      // return {
      //     cardName: "Trailer",
      //     type: "LANDSCAPE",
      //     height: "93px",
      //     width: "165px",
      //     quality: "THUMBNAIL",
      //     playButton: { enablePlay: true, height: "40px", width: "40px" }
      // }
    },
  },
  watch: {
    content(val) {
      this.purgeContent = true;
      this.trailerContents = [];
      this.getTrailersForContent();
    },
  },
  created() {
    this.getTrailersForContent();
  },
  mounted() {
    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });
  },
  methods: {
    getTrailersForContent() {
      if (this.content.trailer && this.content.trailer.length > 0) {
        this.content.trailer.forEach((element) => {
          element.contenttype = "Trailer";
          this.trailerContents.push(element);
        });
        this.purgeContent = false;
      }
    },
    playTrailer(index = 0) {
      this.content.trailerUrl = this.content.trailer[index].filelist[0].filename;
      this.showPlayer();
      let contentList = [this.content];
      this.loadContentMetaData(contentList[0], null);

      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription || content.longdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        isDrmContent: isTrailer ? false : content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: contentHead,
        contentTags: content.tags,
        scrubbing: content.scrubbing ?content.scrubbing[0] : '',

        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre
        }
      });

      return playlistItem;
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    loadNextContentData(endedContentIndex) {
      this.hidePlayer();
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();
      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./trailerListing.scss"
</style>
